// Use this for any shared settings that cross environment bounds.
// TODO: Setup explicit permissions if applicable to your Frontend web application.
// See https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth#Permissions for details

export const permissions = {
  defaultRedirectUrl: '/no-permission-route',
  defaultPermPrefix: 'asset-profile',
  routes: [
    ['/', 'view:asset'],
    ['asset/:id', 'view:asset']
  ]
};
//TODO: change appName (should match datadog app name)
export const appName = 'Asset Profile';
// Typically we would use this to report to Datadog RUM if we have it installed.
export const appVersion = '0.0.1';
