import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { HomePageComponent } from './components/home-page/home-page.component';
import { AssetViewGuard } from './shared/guards/asset-view.guard';
import { HomePageMode } from './consts/app.const';
import { PermissionsGuard } from '@zonar-ui/auth';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    data: {
      mode: HomePageMode.ASSET_PROFILE_BLANK
    },
    pathMatch: 'full',
    canActivate: [AuthGuard, PermissionsGuard]
  },
  {
    path: 'asset/:id',
    loadComponent: () => import('./containers/asset-profile-container/asset-profile-container.component').then((c) => c.AssetProfileContainerComponent),
    canActivate: [AuthGuard, PermissionsGuard, AssetViewGuard]
  },
  {
    path: 'asset-not-found',
    component: HomePageComponent,
    data: {
      mode: HomePageMode.ASSET_NOT_FOUND
    }
  },
  {
    path: '**',
    component: HomePageComponent,
    data: {
      mode: HomePageMode.PAGE_NOT_FOUND
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
