import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Subject } from 'rxjs';

import { FaviconsService } from '@zonar-ui/core';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';

import { appName } from '@environments/shared';

import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import { UserCompany } from '@app/models/user.company.model';
import { Store } from '@ngrx/store';
import { setSelectedCompanyId } from './shared/data-stores/app/app-data-store.actions';
import { SidenavFooterConfig, SidenavHeaderConfig } from './shared/configs/sidenav.config';
import { PendoService } from '@zonar-ui/analytics';
import { PermissionsService } from '@zonar-ui/auth';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnDestroy, OnInit {
  title = appName;

  currentCompany$ = new BehaviorSubject<UserCompany>({
    title: '',
    value: ''
  });
  sidenavHeader = SidenavHeaderConfig;
  sidenavFooter = SidenavFooterConfig;
  sidenavParams: SidenavParams = {
    mobileOpened: true,
    expanded: true,
    footerOffset: false,
  };

  companyId: string = null;
  isFetchPermsSuccess: boolean;
  onDestroy$ = new Subject<void>();

  constructor(
    private auth: AuthService,
    private favicon: FaviconsService,
    private pendoService: PendoService,
    private permissionsService: PermissionsService,
    private router: Router,
    private store: Store<any>,
    private gtcxMobileHelperService: GTCxMobileHelperService,
  ) {
  }

  ngOnInit(): void {
    this.permissionsService
      .getPermissions()
      .pipe(
        takeUntil(this.onDestroy$),
        filter((permissions) => Boolean(permissions))
      )
      .subscribe(() => {
        this.pendoService.initialize();
      });

    this.permissionsService
      .getIsFetchPermsSuccess()
      .pipe(
        filter((permissions) => permissions !== null),
        takeUntil(this.onDestroy$),
      )
      .subscribe((isPermSusscess) => {
        this.isFetchPermsSuccess = isPermSusscess;
      });
  }

  switchCompany(incomingCompany: any): void {
    if (incomingCompany?.value && !this.companyId) {
      this.store.dispatch(setSelectedCompanyId({ payload: { selectedCompanyId: incomingCompany.value } }));
      this.companyId = incomingCompany?.value;
      // Init mobile service each of switching company
      this.gtcxMobileHelperService.setCurrentCompany(incomingCompany.value);
    } else if (this.companyId) {
      this.router.navigate(['/asset-not-found']);
    }
  }

  onSideNavMobileMenuButtonToggled(event): void {
    this.sidenavParams.mobileOpened = event;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
